import { memo, useRef } from "react";
import { injectIntl } from "react-intl";
import { ImageAsyn } from "components/ImageAsyc";
import imageLogo from "assets/img/logo.png";
import { Container, Row } from "reactstrap";
import { Colxx } from "components/common/CustomBootstrap";
import { FadeInTopSpring } from "components/animations/fadeIntTopSpring";
import useVisible from "@tteenbe/hookUseVisible";
import { NavLink } from "components/NavLink";
import { BiHome, BiCut } from "react-icons/bi";
import { FaHandshake, FaPhoneAlt } from "react-icons/fa";

const Footer = () => {
  const elemRefImage = useRef();
  const isVisibleImage = useVisible(elemRefImage);

  return (
    <footer id="footer" className="footer">
      <div className="mad_top_footer">
        <div className="v_align_center_blocks">
          <Container>
            <Row>
              <Colxx sm="4">
                <div className="mad_nav_list">
                  <ul>
                    <li>
                      <NavLink to="/web">
                        <BiHome /> Inicio
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="samyspa">
                        <BiCut /> Samy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="servicios">
                        <FaHandshake /> Servicios
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="contacto">
                        <FaPhoneAlt /> Contacto
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </Colxx>
              <Colxx sm="4" className="text-center">
                <article ref={elemRefImage}>
                  {isVisibleImage && (
                    <FadeInTopSpring>
                      <a
                        href="https://dominio.com"
                        className="mad_logo_container"
                      >
                        <ImageAsyn
                          src={imageLogo}
                          alt=""
                          style={{ width: "10rem" }}
                        />
                      </a>
                    </FadeInTopSpring>
                  )}
                </article>
              </Colxx>
              <Colxx sm="4">
                <div className="align_center">
                  <span>© Copyright 2022 - Samy Spa</span>
                  <br />
                  <div className="mad_nav_list mt-2">
                    <ul>
                      <li>
                        <NavLink to="politica_privacidad">Privacidad</NavLink>
                      </li>
                      <li>
                        <NavLink to="aviso_legal">Aviso Legal</NavLink>
                      </li>
                      <li>
                        <NavLink to="politica_cookies">Cookies</NavLink>
                      </li>
                    </ul>
                  </div>
                  {/* <span>Desarrollado - TteeNbe</span> */}
                </div>
              </Colxx>
            </Row>
          </Container>
        </div>
        {/* <div className="py-2">
                    <Container>
                        <Row>
                            <Colxx md="12" className="text-center">
                            </Colxx>
                        </Row>
                    </Container>
                </div> */}
      </div>
    </footer>
  );
};

export default memo(injectIntl(Footer));
